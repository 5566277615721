import React from 'react';
import ReactDOM from 'react-dom';
import { MsalApp, defaultTheme } from 'agi-ui';

const logo = `${process.env.PUBLIC_URL}/images/pfizer.png`;

const heatMeasures = {
    high: "#0F52BA",
    low: "#6593F5",
    medium: "#0080FF",
    veryHigh: '#003152',
    veryLow: "#95C8D8",
}
export const theme = {
  ...defaultTheme,
    css:{
      sidebarLogoHeight: '90px',
      sidebarLogoWidth:  'auto',
      sidebarLogoMarginTop: '20px',
      sidebarLogoMarginLeft: '10px'
    },
    colors: {
        ...defaultTheme.colors,
        primary: '#117DB8',
        secondary: '#5FC6FF',
        // Disclaimer colour for 'Internal reference' notice
        disclaimer: '#EAAA54',
        // Admin 'invalid input' error fields 
        warning: '#BB0000',
        // Admin Submit/clear buttons
        admin: '#4D4D4D',
        // Highlight colour for dropdowns/filters
        highlight: '#DCF0FF',
        // Selected colour for dropdowns/filters
        selected: '#C7D8E6',
        // Pull out sidebar background
        sidebar: 'linear-gradient(rgb(77, 77, 77) 15%, rgb(57, 57, 57) 81%)',
        // Rep Messages Formulary Win highlighting in HCP Top 5
        isWin: '#1052ba',

        // Filter for sidebar icons (base)
        sidebarIconFilter: '',
        // Hover filter for sidebar icons
        sidebarIconHoverFilter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)',
        // Background hover colour for sidebar icons
        sidebarIconHoverBackground: 'gray',
        // Selected filter for sidebar icons (blue)
        sidebarIconSelectedFilter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',

        // Filter for the excel icon
        excelColorFilter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',

        infoIconColor: heatMeasures.veryHigh,


        // Background colour for logout button
        logoutBackground: 'black linear-gradient(180deg, #4E4E4E 0%, #5B5B5B 100%) 0% 0% no-repeat padding-box',
        // Hover background colour for logout button
        logoutBackgroundHover: 'black linear-gradient(180deg, #5D5D5D 100%, #5C5C5C 0%) 0% 0% no-repeat padding-box'

        
    },
    icons: {
        ...defaultTheme.icons,
        logo,
        sidebarLogo: logo,
        logoHeader: {
          height: "3.5rem",
          width: "auto",
          padding: "1rem .25rem"
      }
    },
    // Pie chart and heatmap colours
    // Chosen in order of appearance
    palette: [
        heatMeasures.veryHigh,
        heatMeasures.high,
        heatMeasures.medium,
        heatMeasures.low,
        heatMeasures.veryLow,
    ],

    // Rep Messages Formulary Win highlighting in HCP Top 5
    isWinEnabled: true,
    winToolTipText: "Formulary Win",

    // Rep Messages - View More header text
    viewMoreHeaderText: "Access",

  titles: {
      ...defaultTheme.titles,
      geoLandscape: "Geographic Landscape - For Directional Use Only",
      payerLandscape: "Payer Landscape - For Directional Use Only",
      payerTopHcps: "Payer Top HCPs - For Directional Use Only",
      repMessages: "Rep Insights - For Directional Use Only",
      admin: "Administration",
      hcpOpportunityScore: "HCP Opportunity Score - For Directional Use Only",
      payerLandscapeGroupPractice: "Payer Landscape - Accounts - For Directional Use Only",
      payerTopGroupPractices:"Payer Top Accounts - For Directional Use Only",
      repMessagesGroupPractice: "Message Insights - For Directional Use Only",
      payerLandscapeTopGroupPractices: 'Top Accounts for'
    },
  //text of the menu items on the side bar
  menuItems: {
    ...defaultTheme.menuItems,
    geoLandscape: "Geographic Landscape",
    payerLandscape: "Payer Landscape",
    payerTopHcps: "Payer Top HCPs",
    repMessages: "Rep Insights",
    admin: "Administration",
    printOnDemandPortal: "Print on Demand Portal - IG",
    PODTooltip: "This includes the products: Cutaquig, Octagam, Panzyga",
    hcpOpportunityScore: "HCP Opportunity Score",
    payerLandscapeGroupPractice: "Payer Landscape - Accounts",
    payerTopGroupPractices: "Payer Top Accounts",
    repMessagesGroupPractice: "Message Insights"
  },

  aliases: {
      hcp: 'HCP',
      groupPractice: 'Account',
    },

  podLinks: [
      {
          url: process.env.REACT_APP_EXTERNAL_POD_LINK2,
          products: [20, 86, 98],
          title: "Print on Demand Portal - Migraine & Velsipity",
          tooltip: "This includes the product: Nurtec ODT, Velsipity"
      }
  ]
};

ReactDOM.render(
  React.createElement(MsalApp, { theme: theme }, null),
  document.getElementById('root')
); 